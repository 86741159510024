.banner-section{
    width: 100%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 2;
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media(max-width:700px){
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &.banner-multiply{
        margin-top: -180px;
        background: transparent;
        pointer-events: none;
        padding-top: 0;
        padding-bottom: 20px;
        @media(max-width:1500px){
            margin-top: -150px;
        }
        @media(max-width:1024px){
            margin-top: 0;
            padding-top: 40px;
            padding-bottom: 20px;
        }
        @media(max-width:700px){
            padding-bottom: 20px;
        }
        .main-banner{
            margin-bottom: 60px;
            @media(max-width:1500px){
                margin-bottom: 45px;
            }
            @media(max-width:1300px){
                margin-bottom: 30px;
            }
            @media(max-width:1024px){
                margin-bottom: 20px;
            }
            .banner__title{
                margin-bottom: 35px;
            }
            .banner-back__icon {
                opacity: 0.3;
            }
        }
        .banner__photo{
            left: 0;
            top: unset;
            transform: translateY(0);
            animation: none;
            bottom: 0;
            max-width: 640px;
            @media(max-width:1700px){
                max-width: 550px;
                left: -20px;
            }
            @media(max-width:1500px){
                max-width: 500px;
            }
            @media(max-width:1350px){
                max-width: 450px;
            }
            @media(max-width:1150px){
                max-width: 400px;
            }
            @media(max-width:1024px){
                max-width: 370px;
                left: -30px;
            }
            @media(max-width:900px){
                max-width: 350px;
            }
            @media(max-width:750px){
                max-width: 300px;
            }
        }
        
    }
}

.banner{
    &-box{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 50px 120px;
        z-index: 1;
        min-height: 350px;
        background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
        border-radius: 10px;
        &.main-banner{
            pointer-events: all;
        }
        @media(max-width:1500px){
            padding: 40px 80px;
            min-height: 300px;
        }
        @media(max-width:1200px){
            padding: 30px 50px;
        }
        @media(max-width:1024px){
            padding: 20px;
            min-height: 250px;
            border-radius: 6px;
        }
        &.process-banner{
            padding: 30px;
            min-height: 280px;
            max-width: 560px;
            @media(max-width:1500px){
                min-height: 220px;
            }
            @media(max-width:1200px){
               max-width: 100%;
               min-height: 200px;
            }
            @media(max-width:1024px){
                min-height: 170px;
                padding: 20px;
            }
            .banner__info{
                max-width: 100%;
            }
            .banner__title{
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 15px;
                @media(max-width:1500px){
                    font-size: 22px;
                    line-height: 28px;
                }
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            .banner__text{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 25px;
                @media(max-width: 1024px){
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 20px;
                }
            }
            .banner__btn {
                min-width: 360px;
                @media(max-width:1200px){
                    min-width: 100%;
                }
            }
            .banner-back__text{
                top: 0;
                right: 30px;
                transform: none;
                @media(max-width:1024px){
                    right: 20px;
                }
            }
        }
    }
    &__info {
        max-width: 920px;
        width: 100%;
        position: relative;
        z-index: 1;
        @media(max-width:1600px){
            max-width: 800px;
        }
        @media(max-width:1500px){
            max-width: 770px;
        }
        @media(max-width:1350px){
            max-width: 650px;
        }
        @media(max-width:1200px){
            max-width: 550px;
        }
        @media(max-width:1024px){
            max-width: 530px;
        }
        @media(max-width:900px){
            max-width: 400px;
        }
        @media(max-width:700px){
            max-width: 100%;
        }
    }
    &__title{
        color: $baseWhite;
        margin-bottom: 15px;
    }
    &__text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: $baseWhite;
        margin-bottom: 35px;
        @media(max-width:1500px){
            margin-bottom: 25px;
            font-size: 20px;
            line-height: 24px;
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    &__btn {
        min-width: 400px;
        .icon{
            max-width: 20px;
            max-height: 25px;
            object-fit: contain;
            transition: .3s;
            fill: $baseWhite;
            margin-right: 10px;
        }
        &.full__btn {
            min-width: 100% !important;
        }
        @media(max-width:700px){
            min-width: 100% !important;
        }
        @media(max-width:576px){
            font-size: 13px;
            line-height: 15px;
        }
    }

    &__photo{
        position: absolute;
        object-fit: contain;
        width: 100%;
        max-width: 540px;
        pointer-events: none;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
        z-index: -1;
        animation: banner-y 5s linear infinite;
        @media(max-width:1700px){
            max-width: 450px;
        }
        @media(max-width:1500px){
            max-width: 400px;
            left: -30px;
        }
        @media(max-width:1350px){
            max-width: 370px;
        }
        @media(max-width:1150px){
            max-width: 350px;
        }
        @media(max-width:1024px){
            max-width: 300px;
            left: -20px;
        }
        @media(max-width:900px){
            max-width: 250px;
        }
        @media(max-width:768px){
            max-width: 220px;
        }
        @media(max-width:700px){
            display: none;
        }
    }
    &-back__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 144px;
        position: absolute;
        line-height: 176px;
        text-align: right;
        color: $baseWhite;
        opacity: 0.12;
        top: 50%;
        transform: translateY(-50%);
        right: 55px;
        z-index: -1;
        span{
            font-size: 280px;
            line-height: 350px;
        }
        @media(max-width:1500px){
            font-size: 100px;
            line-height: 130px;
            span{
                font-size: 200px;
                line-height: 300px;
            }
        }
        @media(max-width:1200px){
            font-size: 70px;
            line-height: 100px;
            span{
                font-size: 160px;
                line-height: 240px;
            }
        }
        @media(max-width:1024px){
            font-size: 60px;
            line-height: 80px;
            right: 30px;
            span{
                font-size: 120px;
                line-height: 150px;
            }
        }
        @media(max-width:700px){
            display: none;
        }
    }
    &-back__icon {
        width: 90px;
        height: 90px;
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: -1;
        opacity: 0.12;
        top: 20px;
        left: 36%;
        .icon{
            max-width: 90px;
            max-height: 90px;
            fill: $baseWhite;
        }
        @media(max-width:1500px){
            width: 70px;
            height: 70px;
            left: 37%;
        }
        @media(max-width:1024px){
            width: 50px;
            height: 50px;
            left: 37%;
        }
        @media(max-width:700px){
            display: none;
        }
    }

    &-row {
        display: flex;
        z-index: 1;
        pointer-events: all;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1300px){
            flex-direction: column;
        }
        .banner-box{
            width: calc(50% - 10px);
            min-height: 260px;
            padding: 40px;
            height: 100%;
            @media(max-width:1500px){
                padding: 30px ;
            }
            @media(max-width:1300px){
                width: 100%;
                margin-bottom: 30px;
                min-height: 200px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            @media(max-width:1024px){
                min-height: 170px;
                padding: 20px;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .banner__info{
            max-width: 100%;
        }
        .banner__text{
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 35px;
            @media(max-width:1500px){
                margin-bottom: 25px;
                font-size: 18px;
                line-height: 22px;
            }
            @media(max-width:1024px){
                margin-bottom: 15px;
                font-size: 14px;
                line-height: 18px;
            }
        }
        .banner__btn {
            min-width: 550px;
            @media(max-width:1300px){
                min-width: 100% !important;
            }
            
        }
    }
}