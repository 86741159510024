.homepage-portfolio-section{
    width: 100%;
    position: relative;
    padding-top: 120px;
    padding-bottom: 250px;
    @media(max-width: 1500px){
        padding-top: 80px;
    }
    @media(max-width: 1300px){
        padding-top: 70px;
        padding-bottom: 210px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.portfolio{
    &-title{
        margin-bottom: 125px;
        @media(max-width:1500px){
            margin-bottom: 100px;
        }
        @media(max-width:1300px){
            margin-bottom: 80px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &__list {
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    &__list-hide{
        // display: none;
        padding-top: 0;
        padding-bottom: 0;
        z-index: 1;
        position: relative;
        height: 100%;
        max-height: 0;
        padding-top: 0;
        overflow: hidden;
        transition: .3s;
        &.active{
            padding-top: 70px;
            max-height: 100%;
            padding-bottom: 100px;
            @media(max-width:1500px){
                padding-bottom: 70px;
                padding-bottom: 0;
            }
            @media(max-width:1300px){
                padding-top: 50px;
                
            }
            @media(max-width:1024px){
                padding-bottom: 0;
            }
        }
        
    }
    &__item {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 70px;
        &:last-child{
            margin-bottom: 0;
        }
        &:nth-child(2n){
            flex-direction: row-reverse;
            .portfolio__info {
                margin-left: 0;
                margin-right: -120px;
                @media(max-width:1500px){
                    margin-right: -100px;
                }
                @media(max-width:1300px){
                    margin-right: -120px;
                }
                @media(max-width:1200px){
                    margin-right: -200px;
                }
                @media(max-width:1024px){
                    margin-right: 0;
                }
            }
        }
        @media(max-width:1300px){
            margin-bottom: 50px;
        }
        @media(max-width:1024px){
            flex-direction: column !important;
            margin-bottom: 50px;
        }
    }
    &__photo{
        width: 50%;
        position: relative;
        @media(max-width:1300px){
            width: 60%;
        }
        @media(max-width:1200px){
            width: 65%;
        }
        @media(max-width:1024px){
            width: 100%;
        }
    }
    &-slider{
        width: 100%;
        position: relative;
        overflow: hidden;
        .swiper-slide{
            img{
                width: 100%;
                position: relative;
                object-fit: contain;
            }
        }
    }
    &__info {
        width: calc(50% + 120px);
        margin-left: -120px;
        position: relative;
        z-index: 1;
        background-color: $secondaryBlack;
        border: 1px solid $baseWhite;
        border-radius: 10px;
        padding: 40px 30px;
        padding-left: 45px;
        @media(max-width:1500px){
            padding: 30px 25px;
            padding-left: 35px;
            width: calc(50% + 100px);
            margin-left: -100px;
        }
        @media(max-width:1300px){
            width: calc(40% + 120px);
            margin-left: -120px;
        }
        @media(max-width:1200px){
            width: calc(35% + 200px);
            margin-left: -200px;
        }
        @media(max-width:1024px){
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
            padding: 20px 15px;
            border-radius: 6px;
        }
    }
    &-item__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: $baseWhite;
        max-width: 700px;
        @media(max-width:1500px){
            font-size: 30px;
            line-height: 38px;
        }
        @media(max-width:1024px){
            font-size: 20px;
            line-height: 30px;
        }
    }
    &-item__subtitle{
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 200;
        font-size: 18px;
        line-height: 22px;
        color: $baseWhite;
        margin-bottom: 30px;
        max-width: 700px;
        @media(max-width:1500px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
        }
    }
    &-item__text{
        width: 100%;
        max-width: 100%;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: $baseWhite;
        overflow: hidden;
        overflow-y: auto;
        max-height: 250px;
        scrollbar-color: $baseWhite #595959;
        scrollbar-width: thin;
        padding-bottom: 15px;
        padding-right: 30px;
        @media(max-width:1500px){
            font-size: 14px;
            max-height: 175px;
        }
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #595959;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseWhite;
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 6px;
            @media(max-width:1500px){
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 4px;
            }
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 18px;
            }
        }
        *{
            max-width: 700px;
        }
        p b{
            font-weight: 600;
            margin-bottom: 4px;
        }
        ul {
            list-style-type:disc ;
            padding-left: 20px;
        }
        li {
            list-style-type: disc;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &-item__text-wrap{
        width: 100%;
        position: relative;
        margin-bottom: 15px;
        &::after{
            width: 100%;
            max-width: 700px;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            background: linear-gradient(360deg, #2B2B2B -9.22%, rgba(43, 43, 43, 0.21) 26.24%);
            pointer-events: none;
        }
    }

    &-loadmore__btn {
        min-width: 370px;
        min-height: 84px;
        font-size: 22px;
        line-height: 27px;
        margin-left: 50%;
        transform: translateX(-50%) translateZ(0) !important;
        margin-top: 100px;
        justify-content: space-between;
        padding: 10px 30px;
        transition: .3s !important;
        span{
            margin: 0 10px;
        }
        .icon{
            word-wrap: 20px;
            height: 10px;
            fill: $baseWhite;
            margin: 0;
        }
        @media(max-width:1500px){
            font-size: 18px;
            line-height: 20px;
            min-height: 70px;
            padding: 10px 20px;
            min-width: 340px;
            margin-top: 70px;
        }
        @media(max-width:1024px){
            min-height: 50px;
            font-size: 14px;
            line-height: 18px;
            padding: 7px 15px;
            min-width: 300px;
            margin-top: 40px;
        }
        @media(max-width:450px){
            min-width: 100%;
        }
        &.hide{
            // opacity: 0;
            // visibility: hidden;
            display: none;
        }
    }
    &-item__btn{
        @media(max-width:576px){
            min-width: 100%;
        }
    }
}