.header {
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  background-color: $baseWhite;
  padding: 25px 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.07);
  @media(max-width:1024px){
    padding: 16px 15px;
  }


  &__container{
    width: 100%;
    max-width: 1680px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 73px;
    margin-right: 40px;
    @media(max-width:1600px){
      margin-right: 25px;
    }
    @media(max-width:1024px){
      min-width: 50px;
      margin-right: 20px;
    }
    img{
      width: 73px;
      object-fit: contain;
      height: 92px;
      @media(max-width:1024px){
        width: 50px;
        height: 63px;
      }
    }
  }

  &__side{
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .header__logo{
      pointer-events: none !important;
      opacity: 0;
      max-height: 0 !important;
      @media(max-width:1024px){
        display: none;
      }
    }
    @media(max-width:1024px){
      width: 100%;
      height: 100%;
      overflow: hidden;
      justify-content: flex-start;
      overflow-y: auto;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  // header burger
  &__burger{
    display: none;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-left: 20px;
    transition: .3s;
    background: transparent;
    border: none;
    outline: none;
    .icon{
      fill: $baseBlack;
      transition: .3s;
      max-width: 30px;
      max-height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .icon-close-burger{
      opacity: 0;
      visibility: hidden;
      // max-width: 20px;
      max-height: 20px;
    }
    &.active{
      .icon-close-burger{
        opacity: 1;
        visibility: visible;
      }
      .icon-burger{
        opacity: 0;
        visibility: hidden;
      }
    }
    @media(max-width:1024px){
      display: flex;
    }
  }

  // header menu
  &__menu{
    display: inline-flex;
    align-items: center;
    li{
      margin: 0 25px;
      @media(max-width:1700px){
        margin: 0 20px;
      }
      @media(max-width:1500px){
        margin: 0 15px;
      }
      @media(max-width:1400px){
        margin: 0 12px;
      }
      @media(max-width:1024px){
        margin: 0;
        margin-bottom: 20px;
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
        @media(max-width:1024px){
          margin-bottom: 0;
        }
      }
    }
    @media(max-width: 1024px){
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
    }
  }

  &__link{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $baseBlack;
    transition: .3s;
    z-index: 1;
    @media(max-width:1200px){
      font-size: 13px;
      line-height: 16px;
    }
    @media(max-width:1024px){
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      padding: 0;
      min-height: auto;
    }
    &:after{
      width: 0%;
      height: 1px;
      background-color: $basePurple;
      transition: .3s;
      position: absolute;
      left: 0;
      bottom: 1px;
      content: '';
    }
    &.active{
      @media(min-width: 1025px){
        color: $basePurple;
        &::after{
          width: 100%;
        }
      }

    }
    &:hover{
      @media(min-width: 1025px){
        color: $basePurple;
        &::after{
          width: 100%;
        }
      }
    }
  }

  &__contacts{
    display: inline-flex;
    align-items: center;
    @media(max-width:1700px){
      flex-direction: column;
      align-items: flex-start;
      margin-right: 30px;
    }
    @media(max-width:1200px){
      margin-right: 30px;
    }
    @media(max-width:1024px){
      margin-bottom: 55px;
    }
  }
  &-contacts__wrapp{
    display: inline-flex;
    align-items: center;
    margin-left: 33px;
    @media(max-width:1600px){
      margin-left: 20px;
    }
    @media(max-width:1024px){
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      width: 100%;
    }
  }

  &-contact__link{
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $baseBlack;
    transition: .3s;
    margin-right: 50px;
    display: flex;
    @media(max-width:1700px){
      margin-right: 0;
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }

    }
    @media(max-width:1350px){
      font-size: 14px;
      line-height: 18px;
    }
    @media(max-width:1200px){
      margin-bottom: 10px;
      .link-value{
        display: none;
      }
    }
    @media(max-width:1024px){
      margin-bottom: 15px;
      .link-value{
        display: inline-flex;
      }
    }
    &:last-child{
      margin-right:40px;
      @media(max-width:1700px){
        margin-right: 0;
      }
    }
    .link-icon {
      max-width: 18px;
      max-height: 18px;
      min-width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      @media(max-width:1350px){
        max-width: 15px;
        max-height: 15px;
        min-width: 15px;
        margin-right: 5px;
      }
      @media(max-width:1200px){
        margin-right: 0;
      }
      @media(max-width:1024px){
        margin-right: 5px;
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
      }
    }
    .icon{
      max-width: 18px;
      max-height: 18px;
      object-fit: contain;
      transition: .3s;
      fill: $baseBlack;
      @media(max-width:1350px){
        max-width: 15px;
        max-height: 15px;
      }
      @media(max-width:1024px){
        max-width: 18px;
        max-height: 18px;
      }
    }
    &:hover{
      color: $baseBlue;
      .icon{
        fill: $baseBlue;
      }
    }
  }

  &-hide__contact {
    display: none !important;
    @media(max-width:1024px){
      display: inline-flex !important;
      margin-bottom: 0;
      .icon{
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  &__btn{
    min-width: 305px;
    @media(max-width:1500px){
      min-width: 260px;
    }
    @media(max-width:1100px){
      min-width: 220px;
    }
    @media(max-width:1024px){
      min-width: 260px;
    }
    @media(max-width:450px){
      min-width: 100%;
    }
  }
  &__socials{
    @media(max-width:1024px){
      display: inline-flex;
      width: calc(100% + 12px);
      margin-left: -6px;
      margin-right: -6px;
      margin-bottom: 35px;
    }
  }

  &-contacts-box{
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-right: 75px;
    @media(max-width:1700px){
      margin-right: 40px;
    }
    @media(max-width:1600px){
      margin-right: 30px;
    }
    @media(max-width:1400px){
      margin-right: 25px;
    }
    @media(max-width:1350px){
      display: none;
    }
    @media(max-width:1024px){
      display: flex;
      margin-bottom: 30px;
    }
    .contacts-info__link.header-contact-link{
      color: $basePurple;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      margin: 12px auto 0 auto;
      width: fit-content;
      @media(max-width:1600px){
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
      }
      @media(max-width:1024px){
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 10px;
      }
      .icon{
        fill: $basePurple;
        width: 18px;
        height: 18px;
      }
    }
  }


  // mobile menu

  &-mobile__menu{
    min-width:100%;
    margin-left: -113px;
    display: flex;
    align-items: center;
    @media(max-width:1600px){
      margin-left: -98px;
    }
    @media(max-width:1024px){
      margin-left: 0;
      position: fixed;
      top: 95px;
      z-index: 10;
      left: 0;
      background-color: $baseWhite;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - 95px);
      padding: 25px 15px;
      border-top: 1px solid $lightBlack;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      transform: translateX(110%);
      transition: .4s;
      transition-delay: .15s;
      &.active{
        transform: translateX(0);
        transition-delay: 0;
      }
    }
  }
}
.overlay{
  position: fixed;
  z-index: 3;
  background-color: rgba($baseWhite , 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backdrop-filter: blur(2px);
  &.active{
    opacity: 1;
    visibility: visible;
  }
}
