.homepage-quize-section{
    padding-top: 120px;
    padding-bottom: 60px;
    z-index: 2;
    @media(max-width:1500px){
        padding-top: 100px;
        padding-bottom: 50px;
    }
    @media(max-width:1024px){
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
.quize{
    &-title{
        margin-bottom: 120px;
        @media(max-width:1500px){
            margin-bottom: 100px;
        }
        @media(max-width:1300px){
            margin-bottom: 80px;
        }
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
    }
    &-box{
        width: 100%;
        position: relative;
        max-width: 1680px;
        margin: 0 auto;
        z-index: 1;
        min-height: 450px;
        border-radius: 10px;
        padding: 50px 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        @media(max-width:1500px){
            padding: 40px 80px;
            min-height: 400px;
        }
        @media(max-width:1300px){
            min-height: 350px;
        }
        @media(max-width:1200px){
            padding: 30px 50px;
        }
        @media(max-width:1024px){
            padding: 20px;
            min-height: 300px;
            border-radius: 6px;
        }
        @media(max-width:900px){
            min-height: 250px;
        }
        .figure-quize-box{
            position: absolute;
            z-index: -3;
            top: 0;
            right: 0;
            max-width: 150px;
            max-height: 150px;
            img{
                width: 100%;
                object-fit: contain;
                pointer-events: none;
            }
            &.figure-quize-box-triangle{
                max-width: 120px;
                max-height: 120px;
                bottom: -10%;
                top: unset;
                right: 40%;
                animation: float-y 9s linear infinite;
                @media(max-width:1024px){
                    max-width: 60px;
                    max-height: 60px;
                }
            }
            &.figure-quize-box-squere{
                max-width: 85px;
                max-height: 85px;
                left: -2.5%;
                top: 40%;
                animation: float-y 5s linear infinite;
                @media(max-width:1024px){
                    max-width: 45px;
                    max-height: 45px;
                    left: 0;
                }
            }
            &.figure-quize-box-circle{
                max-width: 150px;
                max-height: 150px;
                right: -4%;
                top: -10%;
                animation: float-y 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 70px;
                    max-height: 70px;
                }
            }
        }
        &::after{
            background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
            opacity: 0.64;
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    &-back__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        z-index: -2;
        border-radius: 10px;
    }

    &-box__info{
        width: 100%;
        max-width: 750px;
        margin-right: 100px;
        position: relative;
        z-index: 1;
        @media(max-width:1700px){
            margin-right: 0;
        }
        @media(max-width:1500px){
            max-width: 600px;
        }
        @media(max-width:1350px){
            max-width: 570px;
        }
        @media(max-width:1024px){
            max-width: 480px;
        }
        @media(max-width:850px){
            max-width: 420px;
        }
        @media(max-width:800px){
            max-width: 350px;
        }
        @media(max-width:700px){
            max-width: 100%;
        }
    }
    &-box__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: $baseWhite;
        margin-bottom: 25px;
        @media(max-width:1500px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 20px;
        }
        @media(max-width:1024px){
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 15px;
        }
    }
    &-box__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $baseWhite;
        margin-bottom: 45px;
        @media(max-width:1500px){
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 35px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }
    &-banner__btn{
        min-width: 400px;
        @media(max-width:1024px){
            min-width: 100%;
        }
        .icon{
            fill: $baseWhite !important;
        }
    }
    &-box__photo {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: -100px;
        max-width: 910px;
        z-index: 0;
        img{
            max-width: 100%;
            width: 100%;
            object-fit: contain;
            pointer-events: none;
        }
        @media(max-width:1600px){
            max-width: 800px;
        }
        @media(max-width:1500px){
            max-width: 740px;
            left: -60px;
        }
        @media(max-width:1350px){
            max-width: 700px;
        }
        @media(max-width:1300px){
            max-width: 650px;
        }
        @media(max-width:1200px){
            max-width: 570px;
        }
        @media(max-width:1100px){
            max-width: 500px;
        }
        @media(max-width:1024px){
            max-width: 470px;
            left: -40px;
        }
        @media(max-width:900px){
            max-width: 400px;
            left: -40px;
        }
        @media(max-width:700px){
            display: none;
        }
    } 
}