.quize-stepper{
    &__box{
        width: 100%;
        max-width: 1680px;
        position: relative;
        z-index: 1;
        margin: auto;
        opacity: 0;
        transition: .5s;
        transition-delay: .3s;
        visibility: hidden;
        .figure-quize-box{
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            max-width: 150px;
            max-height: 150px;
            img{
                width: 100%;
                object-fit: contain;
                pointer-events: none;
            }
            &.figure-quize-box-triangle{
                max-width: 120px;
                max-height: 120px;
                bottom: -10%;
                top: unset;
                right: 40%;
                animation: float-y 9s linear infinite;
                @media(max-width:1024px){
                    max-width: 60px;
                    max-height: 60px;
                }
            }
            &.figure-quize-box-squere{
                max-width: 85px;
                max-height: 85px;
                left: -2.5%;
                top: 40%;
                animation: float-y 5s linear infinite;
                @media(max-width:1024px){
                    max-width: 45px;
                    max-height: 45px;
                    left: 0;
                }
            }
            &.figure-quize-box-circle{
                max-width: 150px;
                max-height: 150px;
                right: -4%;
                top: -10%;
                animation: float-y 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 70px;
                    max-height: 70px;
                }
            }
        }
    }
    &__slider {
        width: 100%;
        position: relative;
        z-index: 1;
        .swiper-slide{
            opacity: 0;
            transition: .3s;
            &.swiper-slide-active{
                opacity: 1;
            }
        }
        
    }
    &-main__img{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: -140px;
        max-width: 1040px;
        z-index: 1;
        object-fit: contain;
        pointer-events: none;
        visibility: visible;
        opacity: 1;
        transition: .3s;
        transition-delay: .5s;
        &.hide{
            opacity: 0;
            transition-delay: 0s;
            visibility: hidden;
        }
        @media(max-width:1700px){
            max-width: 900px;
            left: -120px;
        }
        @media(max-width:1650px){
            max-width: 800px;
        }
        @media(max-width:1600px){
            max-width: 750px;
        }
        @media(max-width:1550px){
            max-width: 700px;
        }
        @media(max-width:1500px){
            max-width: 650px;
        }
        @media(max-width:1450px){
            max-width: 600px;
        }
        @media(max-width:1400px){
            max-width: 550px;
            left: -70px;
        }
        @media(max-width:1350px){
            max-width: 500px;
        }
        @media(max-width:1300px){
            display: none !important;
        }
    }
    &__item{
        width: 100%;
        position: relative;
        height: fit-content;
        min-height: 640px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
        @media(max-width:1500px){
            min-height: 530px;
        }
        @media(max-width:1200px){
            padding: 30px;
        }
        @media(max-width:1024px){
            padding: 20px;
            min-height: 400px;
        }
        &.first__slide{
            justify-content: flex-end;
            .quize-stepper__info {
                max-width: 900px;
                @media(max-width:1300px){
                    max-width: 1300px;
                }
            }
            .quize-stepper__title{
                margin-bottom: 70px;
                max-width: 100%;
                @media(max-width:1500px){
                    font-size: 26px;
                    line-height: 32px;
                    margin-bottom: 50px;
                }
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 30px;
                }
            }
            .stepper-next__btn{
                @media(max-width:576px){
                    min-width: 100%;
                }
            }
        }
        &.form-step{
            .quize-stepper__info  {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .quize-stepper__title{
                max-width: 100%;
                text-align: center;
                margin-bottom: 20px;
                @media(max-width:1024px){
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 15px;
                }
            }
            .quize-stepper__text{
                max-width: 100%;
                text-align: center;
            }
            .quize-stepper__navigation{
                justify-content: center;
            }
        }
        &::after{
            background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
            opacity: 0.64;
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    } 
    &-back__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        z-index: -2;
        border-radius: 10px;
    }
    &__info {
        width: 100%;
        max-width: 1300px;
        @media(max-width:1024px){
            max-width: 100%;
        }
    }

    &__title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: $baseWhite;
        margin-bottom: 30px;
        max-width: 850px;
        @media(max-width:1500px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
        }
        @media(max-width:1024px){
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }
    &__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $baseWhite;
        margin-bottom: 50px;
        max-width: 680px ;
        &.quize-stepper__thanks{
            margin-bottom: 30px;
            @media(max-width:1500px){
                margin-bottom: 25px;
            }
            @media(max-width:1024px){
                margin-bottom: 15px;
            }
        }
        @media(max-width:1500px){
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 25px;
        }
    }

    &__navigation{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    // radios
    &-radio__list{
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        @media(max-width:1500px){
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            margin-bottom: 30px;
        }
    }
    &-radio {
        display: inline-flex;
        cursor: pointer;
        transition: .3s;
        width: fit-content;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        input{
            display: none;
        }
        input:checked + .quize-stepper-radio__retangle:after{
            opacity: 1;
        }
        &:hover{
            .quize-stepper-radio__retangle {
                border-color: $basePurple;
            }
            .quize-stepper-radio__text{
                opacity: 0.7;
            }
        }
        &.error-radio{
            .quize-stepper-radio__retangle {
                border-color: $baseRed !important;
            }
            .quize-stepper-radio__text{
                color: $baseRed !important;
            } 
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
    }
    &-radio__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: $baseWhite;
        line-height: 24px;
        transition: .3s;
        max-width: 800px;
        @media(max-width:1500px){
            font-size: 18px;
            line-height: 22px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
        }
        @media(max-width:576px){
            font-size: 13px;
            line-height: 16px;
        }
    }
    &-radio__retangle{
        width: 20px;
        height: 20px;
        min-width: 20px;
        border: 1px solid $baseWhite;
        border-radius: 3px;
        transition: .3s;
        margin-right: 12px;
        overflow: hidden;
        position: relative;
        @media(max-width:1024px){
            width: 18px;
            height: 18px;
            min-width: 18px;
            margin-right: 10px;
            border-radius: 3px;
        }
        @media(max-width:576px){
            width: 15px;
            height: 15px;
            min-width: 15px;
        }
        
        &::after{
            background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
            content: '';
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            transition: .3s;
            opacity: 0;
            border-radius: 3px;
        }
    }
    // radios end

    &__submit{
        min-width: 320px;
        min-height: 70px;
        @media(max-width:1024px){
            min-height: 50px;
        }
        @media(max-width:576px){
            min-width: calc(100% - 65px);
        }
    }
    &__formlist{
        width: 100%;
        max-width: 1000px;
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
        @media(max-width:700px){
            margin-bottom: 0;
        }
        .form-field{
            width: 50%;
            padding: 0 10px;
            @media(max-width:700px){
                width: 100%;
            }
        }
        .form__input{
            background-color: transparent;
            color: $baseWhite;
            &::placeholder{
                color: $baseWhite;
            }
        }
    }
}