.homepage-hero-section {
    padding-top: 0;
    padding-bottom: 0;
    z-index: 2;
    @media(max-width:1024px){
        padding-top: 20px;
        padding-bottom: 60px;
        overflow: hidden;
    }
    .global-container{
        height: auto;
        display: flex;
        flex-direction: column;
    }
}
// hero
.hero{
    &-box{
        width: 100%;
        position: relative;
        padding-top: 10px;
        padding-bottom: 140px;
        z-index: 1;
        min-height: calc(100vh - 142px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media(min-width:2000px) and (min-height:1200px){
            min-height: 1000px;
        }
        @media(max-width:1650px){
            padding-bottom: 80px;
        }
        @media(max-width:1300px){
            padding-bottom: 40px;
            &::after{
                width: calc(100% + 100px);
                height: 100%;
                background-color: rgba($baseWhite,0.7);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                filter: blur(1.5px);
                content: '';
                @media(max-width:1024px){
                    height: calc(100% + 80px);
                    top: -20px;
                    background-color: rgba($baseWhite,0.77);
                }
                @media(max-width:576px){
                    background-color: rgba($baseWhite,0.8);
                }
            }
        }
        @media(max-width:1024px){
            min-height: auto;
            padding: 0;
        }

    }
    // hero info block
    &__info{
        width: 100%;
        max-width: 750px;
        min-width: 750px;
        position: relative;
        z-index: 1;
        @media(max-width:1650px){
            min-width: 600px;
            max-width: 600px;
        }
        @media(max-width:1400px){
            min-width: 500px;
            max-width: 500px;
        }
        @media(max-width:1300px){
            min-width: 100%;
            max-width: 100%;
            position: relative;

        }
        @media(max-width:576px){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
        text-transform: uppercase;
        color: $secondaryBlack;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        max-width: 750px;
        @media(max-width:1650px){
            font-size: 44px;
            line-height: 52px;
        }
        @media(max-width:1400px){
            font-size: 36px;
            line-height: 44px;
        }
        @media(max-width:1024px){
            font-size: 30px;
            line-height: 40px;
            max-width: 610px;
        }
        @media(max-width:576px){
            font-size: 24px;
            line-height: 32px;
            max-width: 610px;
        }
        @media(max-width:576px){
            text-align: center;
        }
        .figure-hero-title{
            position: absolute;
            max-width: 50px;
            max-height: 50px;
            object-fit: contain;
            transition: .3s;
            &.figure-hero-title-one{
                max-width: 37px;
                max-height: 37px;
                top: -5%;
                left: 10%;
                animation: move-on 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 25px;
                    max-height: 25px;
                    animation: move-on-media 7s linear infinite;
                }
                @media(max-width:576px){
                    left: 20%;
                }
            }
            &.figure-hero-title-two{
                max-width: 30px;
                max-height: 30px;
                bottom: 20%;
                left: 17%;
                transform: rotate(20deg);
                animation: move-on 9s linear infinite;
                @media(max-width:1024px){
                    max-width: 20px;
                    max-height: 20px;
                    animation: move-on-media 9s linear infinite;
                }
                @media(max-width:576px){
                    left: 20%;
                }
            }
            &.figure-hero-title-three{
                max-width: 35px;
                max-height: 35px;
                top: 2%;
                right: 35%;
                animation: move-on 12s linear infinite;
                @media(max-width:1024px){
                    max-width: 25px;
                    max-height: 25px;
                    animation: move-on-media 12s linear infinite;
                }
            }
            &.figure-hero-title-four{
                max-width: 32px;
                max-height: 32px;
                top: 50%;
                right: 50%;
                animation: move-on 10s linear infinite;
                @media(max-width:1024px){
                    max-width: 20px;
                    max-height: 20px;
                    animation: move-on-media 10s linear infinite;
                }
                @media(max-width:576px){
                    right: 30%;
                }
            }
            &.figure-hero-title-five{
                max-width: 25px;
                max-height: 25px;
                top: 65%;
                left: -2%;
                animation: move-on 17s linear infinite;
                @media(max-width:1024px){
                    max-width: 18px;
                    max-height: 18px;
                    animation: move-on-media 17s linear infinite;
                }
                @media(max-width:576px){
                    left: 5%;
                }
            }
            &.figure-hero-title-six{
                max-width: 20px;
                max-height: 20px;
                top: 25%;
                left: 30%;
                animation: move-on 7s linear infinite;
                @media(max-width:1024px){
                    max-width: 15px;
                    max-height: 15px;
                    animation: move-on-media 7s linear infinite;
                }
            }
            &.figure-hero-title-seven{
                max-width: 50px;
                max-height: 50px;
                top: 25%;
                left: -2%;
                animation: move-on 10s linear infinite;
                @media(max-width:1024px){
                    max-width: 30px;
                    max-height: 30px;
                    animation: move-on-media 10s linear infinite;
                }
            }
            &.figure-hero-title-eight{
                max-width: 50px;
                max-height: 50px;
                top: 30%;
                right: 15%;
                transform: rotate(-50deg);
                animation: move-on 12s linear infinite;
                @media(max-width:1024px){
                    max-width: 30px;
                    max-height: 30px;
                    animation: move-on-media 12s linear infinite;
                }
                @media(max-width:576px){
                    right: 10%;
                }
            }
        }
    }
    &__subtitle{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 50px;
        max-width: 750px;
        @media(max-width:1650px){
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
            max-width: 610px;
        }
        @media(max-width:576px){
            text-align: center;
        }
    }
    &__text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: $secondaryBlack;
        margin-bottom: 55px;
        max-width: 650px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1650px){
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        @media(max-width:576px){
            max-width: 100%;
            text-align: center;
            font-weight: 400;
        }
      .clutch-widget {
        max-width: 120px;
        background-color: #fff;
        margin-left: 20px;
      }
    }
    // hero info block end

    // photo block
    &__photo{
        width: calc(100% - 650px);
        position: absolute;
        z-index: 0;
        height: 100%;
        right: 0;
        top: 0;
        img{
            pointer-events: none;
            transition: .3s;
        }
        @media(max-width:1650px){
            width: calc(100% - 600px);
        }
        @media(max-width:1400px){
            width: calc(100% - 500px);
        }
        @media(max-width:1300px){
            width: 100%;
            filter: blur(1.5px);
        }
        @media(max-width:1024px){
            height: calc(100% + 60px);
            top: -20px;
            filter: blur(3px);
        }
        @media(max-width:576px){
            filter: blur(2px);
        }
    }
    &-moon__img{
        position: absolute;
        z-index: 0;
        bottom: -100px;
        right: -160px;
        width: 100%;
        max-height: calc(100% + 110px);
        object-fit: contain;
        animation: rotated linear 70s infinite;
        @media(max-width:1650px){
            right: -100px;
            width: calc(100% + 60px);
        }
        @media(max-width:1500px){
            bottom: -70px;
            max-height: calc(100% + 70px);
        }
        @media(max-width:1400px){
            bottom: -50px;
            max-height: calc(100% + 50px);
            right: -70px;
        }
        @media(max-width:1300px){
            bottom: unset;
            top: 0;
            max-height: 100%;
            height: 100%;
        }
        @media(max-width:1024px){
            right: -60px;
            top: 0;
            animation-duration: 30s;
            width: auto;
            height: 100%;
            max-height: 100%;
        }
        @media(max-width:576px){
            right: unset;
            left: -50%;
        }
    }
    &-laptop__img{
        position: absolute;
        z-index: 0;
        bottom: 40px;
        left: 18%;
        pointer-events: all;
        width:72%;
        max-height: 100%;
        object-fit: contain;
        animation: float-x 10s linear infinite;
        @media(max-width:1650px){
            left:10%;
            bottom: 50px;
            max-height: calc(100% - 45px);
        }
        @media(max-width:1500px){
            bottom: 40px;
            left: 8%;
            max-height: calc(100% - 35px);
        }
        @media(max-width:1400px){
            left: 7%;
            bottom: 17px;
            max-height: calc(100% - 20px);
        }
        @media(max-width:1300px){
            display: none;
        }
    }
    &-rocket__img{
        position: absolute;
        z-index: 0;
        width: 79%;
        max-width: 100%;
        max-height: 70%;
        object-fit: contain;
        right: -300px;
        bottom: -40px;
        transform: translateY(0%) translateX(-20px);
        animation: float-y linear 4s infinite;
        @media(max-width:1650px){
            right: -250px;
        }
        @media(max-width:1500px){
            right: -200px;
        }
        @media(max-width:1400px){
            right: -180px;
        }
        @media(max-width:1300px){
            right: -150px;
            bottom: 0;
        }
        @media(max-width:1024px){
            width: 100%;
            right: 0;
            height: auto;
            max-height: 100%;
        }
        @media(max-width:576px){
            right: -70px;
        }
    }
    // photo block end
}
