/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form{
    &-fieldlist{
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 16px);
            margin-left: -8px;
            margin-right: -8px;
            margin-bottom: 5px;
        }
    }
    &-field{
        width: 50%;
        padding: 0 15px;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @media(max-width:1024px){
            padding: 0 8px;
            margin-bottom: 20px;
        }
        @media(max-width:576px){
            width: 100%;
        }
        &.full-field{
            width: 100%;
        }
    }
    &-field__name{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $baseWhite;
        transition: .3s;
        margin-bottom: 15px;
        @media(max-width:1024px){
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 10px;
        }
    }
    &-field__input {
        width: 100%;
        position: relative;
    }
    &__input{
        width: 100%;
        position: relative;
        transition: .3s;
        border-radius: 100px;
        background-color: $baseWhite;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #9C9C9C;
        padding: 20px 40px;
        outline: none;
        border: 1px solid $baseWhite;
        min-height: 70px;
        display: flex;
        align-items: center;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
            padding: 10px 25px;
            min-height: 50px;
        }
        &::placeholder{
            color: #9C9C9C;
            transition: .3s;
        }
        &:hover{
            border-color: $baseBlue;
        }
        &:focus{
            border-color: $basePurple;
        }
    }
}
//select dropdown
.select-info{
    &.active{
        .icon-trigger{
            transform: rotate(180deg);
        }
        .select__trigger{
            border-color: $basePurple !important;
        }
    }
    .select-info{
        box-sizing: border-box;
        position: relative;
        width:100%;
        background: transparent;
        transition: .3s;
    }
    .select__list{
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-color: $baseBlack #d8d8d8;
        scrollbar-width: thin;
        padding-right: 15px;
        max-height: 155px;
        @media(max-width:1024px){
            padding-right: 7px;
            max-height: 131px;
        }
        &::-webkit-scrollbar{
            width: 3px;
            background-color: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb{
            width: 3px;
            background-color: $baseBlack;
        }
    }
    .select__dropdown {
        position: absolute;
        width: 100%;
        background: transparent;
        display: none;
        padding: 7px 30px;
        z-index: 10;
        margin-top: 20px;
        background: $baseWhite;
        border-radius: 20px;
        padding-right: 15px;
        @media(max-width:1024px){
            padding: 5px 15px;
            padding-right: 7px;
            border-radius: 10px;
            margin-top: 10px;
        }
    }

    .select__trigger {
        width: 100%;
        min-height: 70px;
        border: 1px solid $baseWhite;
        background-color: $baseWhite;
        border-radius: 100px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        z-index: 1;
        transition: .3s;
        padding: 20px 40px;
        @media(max-width:1024px){
            padding: 10px 25px;
            min-height: 50px;
            padding-right: 30px;
        }
        &:hover{
            border-color: $baseBlue;
        }
    }
    .select-arrow {
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 25px;
        top:50%;
        transform: translateY(-50%);
        @media(max-width:1024px){
            width: 14px;
            height: 14px;
            right: 15px;
        }
        .icon{
            width: 17px;
            height: 9px;
            fill: #757575;
            transition: .3s;
            @media(max-width:1024px){
                width: 14px;
                height: 7px;
            }
        }
    }
    

    .select__value{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #9C9C9C;
        transition: .3s;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
        }
    }
    .select__option{
        width: 100%;
        padding: 15px 5px;
        border-bottom: 1px solid $baseGray;
        cursor: pointer;
        @media(max-width:1024px){
            padding: 7px 5px;
        }
        &:last-child{
            border: none;
        }
        &.active{
            .select__option-info{
                background: linear-gradient(89.91deg, #11BBF0 -7.12%, #D83EFF 112.36%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-weight: 400;
            }
        }
        &:hover{
            .select__option-info{
                color: $basePurple;
            }
        }
    }

    .select__option-info{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        transition: .3s;
        line-height: 20px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 18px;
        }
    }
    
    .select__option.active{
        box-shadow: none;
        max-width: 100%;
    }
}

.form-item{
    &.error, &.error__email{
        .form-field__name{
            color: $baseRed !important;
        }
        .form__input{
            border-color: $baseRed !important;
            &::placeholder{
                color: $baseRed !important;
            }
        }
        .select__trigger{
            border-color: $baseRed !important;
        }
        .select__value{
            color: $baseRed !important;
        }
    }
}
// intl
.iti{
    width: 100% !important;
}
.iti__selected-flag{
    padding-left: 15px;
    padding-right: 5px;
}
.iti__country-list{
    padding: 0;
    max-width: 580%;
    @media(max-width:1650px){
        max-width: 520%;
    }
    @media(max-width:1550px){
        max-width: 480%;
    }
    @media(max-width:1450px){
        max-width: 560%;
    }
    @media(max-width:1024px){
        min-width: 260px;
        max-width: calc(100% - 30px);
        margin-left: -16px;
        max-height: 150px;
    }
}
.iti__divider{
    padding: 0;
    margin: 0;
}
  // intl end