@keyframes rotated {
  0% {
  transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
}

@keyframes float-y {
  0% {
    transform: translateY(0%) translateX(-20px);
    
  }
  50% {
    transform: translateY(5%) translateX(-40px);
  }
  100% {
    transform: translateY(0%) translateX(-20px); 
  }
}
@keyframes float-x {
  0% {
    transform: translateX(0%) translateY(0) rotate(0);
    
  }
  50% {
    transform: translateX(5%) translateY(2%) rotate(10deg);
  }
  100% {
    transform: translateX(0%) translateY(0) rotate(0); 
  }
}

@keyframes banner-y {
  0% {
    transform: translateY(-50%) translateX(-0px);
    
  }
  50% {
    transform: translateY(-55%) translateX(-20px);
  }
  100% {
    transform: translateY(-50%) translateX(-0px); 
  }
}

@keyframes blik{
  0% {
    opacity: 0.8;
    
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8; 
  }
}


@keyframes move-on {
  0%{
    transform: translate(0,0);
  }
  25%{
    transform: translate(20px,10px) rotate(90deg);
  }
  50%{
    transform: translate(30px, 50px) rotate(200deg);
  }
  75%{
    transform: translate(20px,-10px) rotate(90deg);
  }
  100%{
    transform: translate(0,0);
  }
}

@keyframes move-on-media {
  0%{
    transform: translate(0,0);
  }
  25%{
    transform: translate(10px,15px) rotate(90deg);
  }
  50%{
    transform: translate(15px, 25px) rotate(200deg);
  }
  75%{
    transform: translate(10px,-5px) rotate(90deg);
  }
  100%{
    transform: translate(0,0);
  }
}

@keyframes float {
  0%{
    transform: translate(0,0);
  }
  25%{
    transform: translate(10px,10px);
  }
  50%{
    transform: translate(0, 10px);
  }
  75%{
    transform: translate(10px,10px);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes image-scale {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.15);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes scale-img{
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes float-opacity {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}